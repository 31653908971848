<template>
  <div>
    <b-modal
      id="bridgeModal"
      ref="bridgeModal"
      hide-header
      hide-footer
      centered
      body-class="text-center text-black mb-4"
      @hide="modalEvent(false)"
      @show="modalEvent(true)"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="$refs.bridgeModal.hide()" class="float-right">
            <img src="/img/threespace/icon/x-icon.png" />
          </CLink>
        </CCol>
      </CRow>
      <div class="bridge-modal-title">
        $PACE Bridge
      </div>
      <div class="bridge-modal-content">
        <div class="bridge-modal-content-subject">
          {{ $t("bridge.token")}}
        </div>
        <div class="bridge-modal-content-token">
          <img src="/img/threespace/logo/logo_100x100.png"/>
          <span>PACE</span>
        </div>
        <div class="wrapper">
          <div>
            <div class="bridge-modal-content-subject">
              From
            </div>
            <div class="network">
              <b-button
                @click.stop="showSortByFilter('origin')"
                class="sort_btn float-right"
                :class="{ active: visibleOrigin }"
              >
                {{originSelected.text ? originSelected.text : 'Network'}}
                <img
                  class="float-right"
                  src="/img/threespace/icon/sortby_arrow.png"
                />
              </b-button>
              <div
                class="sort_select_list"
                :class="{ open: visibleOrigin }"
                v-click-outside:showSortByFilter="false"
              >
                <template v-for="(item, i) in originOptions">
                  <p
                    v-if="item.value.id!=='polygon'"
                    class="mb-0"
                    :key="i"
                    @click="onClickSortByItem(item, 'origin')"
                    :class="{ active: originSelected.text === item.text }"
                  >
                    {{ item.text }}
                  </p>
                  <p
                    v-else
                    class="mb-0 disabled"
                    :key="i"
                    disabled
                  >
                    {{ item.text }}
                  </p>
                </template>
              </div>
            </div>
          </div>
          <span v-if="me.klaytnAddr && me.ethereumAddr"
                class="repeat-icon"
                @click="changeNetwork()"
          >
            <img src="/img/threespace/icon/refeat.png"/>
          </span>
          <div>
            <div class="bridge-modal-content-subject">
              To
            </div>
            <div class="network">
              <b-button
                class="sort_btn float-right"
                :class="{ active: visibleDestination }"
              >
                {{destinationSelected.text ? destinationSelected.text : ''}}
              </b-button>
              <div
                class="sort_select_list"
                :class="{ open: visibleDestination }"
                v-click-outside:showSortByFilter="false"
              >
                <template v-for="(item, i) in destinationOptions">
                  <p
                    class="mb-0"
                    :key="i"
                    @click="onClickSortByItem(item, 'destination')"
                    :class="{ active: destinationSelected.text === item.text }"
                  >
                    {{ item.text }}
                  </p>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="bridge-modal-content-subject">
          {{ $t("bridge.conversionAmount")}}
        </div>
        <div class="wrapper">
          <CInput class="bold"
                  :placeholder="0"
                  type="number"
                  v-model="convertAmount"
                  @input="onChangeAmount"
          />
          <div class="medium right">
            {{`Balance : ${balance}`}}
          </div>
          <b-button @click="onClickMaxBtn" class="max-button">MAX</b-button>
        </div>
        <div class="wrapper">
          <div class="bridge-modal-content-subject small">
            {{ $t("bridge.fee")}}
          </div>
          <div class="bridge-modal-content-subject small right">
            {{ `${swapFee} ${swapFeeToken}` }}
          </div>
        </div>
        <div class="small-noti" v-if="originSelected.value && originSelected.value.id === 'klaytn'">
          {{ $t("bridge.klaytnFee") }}
        </div>
        <div class="wrapper">
          <div class="bridge-modal-content-subject">
            {{ $t("bridge.receiveAmount")}}
          </div>
          <div class="bridge-modal-content-subject right">
            {{ `${receiveAmount} ${convertedToken}` }}
          </div>
        </div>
        <div class="bridge-modal-content-subject">
          {{ $t("bridge.recipientTitle")}}
        </div>
        <input
          class="bridge-modal-content-token"
          v-model="recipient"
          :placeholder="$t('bridge.recipient')"
        />
        <b-button @click="onClickConvert" class="bridge-modal-convert-button">
          {{ $t("bridge.convert") }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="convertModal"
      ref="convertModal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      body-class="text-center text-black my-4"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="$refs.convertModal.hide()" class="float-right">
            <img src="/img/threespace/icon/x-icon.png" />
          </CLink>
        </CCol>
      </CRow>
      <div class="convert-modal-title">{{ $t("bridge.bridgeTitle") }}</div>
      <div class="convert-modal-text">{{ $t("bridge.convertAmount") }}</div>
      <div class="convert-modal-container">{{ `${receiveAmount} ${convertedToken}` }}</div>
      <div class="convert-modal-text small right">{{ `${$t("bridge.fee")}: ${swapFee} ${swapFeeToken}` }}</div>
      <div class="convert-modal-text">{{ $t("bridge.recipientTitle") }}</div>
      <div class="convert-modal-container">{{ recipient }}</div>
      <div class="convert-modal-notice">
        <div class="convert-modal-text">{{ $t("claim.guide.title") }}</div>
        <p>• {{ $t("bridge.notice1") }}</p>
        <p>• {{ $t("bridge.notice2") }}</p>
        <p>• {{ $t("bridge.notice3") }}</p>
        <p>• {{ $t("bridge.notice4") }}</p>
      </div>
      <b-form-checkbox
        v-model="agreeNotice"
      >
        {{ $t("bridge.confirmText")}}
      </b-form-checkbox>
      <b-button @click="onClickConvertNow" class="convert-modal-button">
        {{ $t("bridge.convert2") }}
      </b-button>
    </b-modal>

    <b-modal
      id="klipModal"
      ref="klipModal"
      hide-header
      hide-footer
      centered
      body-class="text-center text-black my-4"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="$refs.klipModal.hide()">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <!-- 카카오톡 클립(PC)으로 연결 -->
      <template v-if="form.isKakaoKlipPc">
        <div class="w-100 d-flex justify-content-center align-items-center">
          <img
            src="/img/threespace/company/kakao_klip_logo_color.png"
            width="50"
            class="float-left"
          />
          <h5 class="ml-2 mb-0">{{ $t("wallet.modal.klipQRTitle") }}</h5>
        </div>
        <div class="w-100 d-flex justify-content-center">
          <img :src="klip.qrcode" width="160" height="160" class="border-1" />
        </div>
        <div class="w-100 text-center">
          <p>
            <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
            <span class="text-danger">{{ time }}</span>
          </p>
        </div>
        <div class="w-100 text-center mt-3">
          <p>
            {{ $t("wallet.modal.klipQRdescription") }}
          </p>
          <p class="text-gray">
            {{ $t("wallet.modal.klipQRdescription2") }}
          </p>
        </div>
        <div class="row d-flex justify-content-center align-items-center mt-3">
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-logo.svg" />
          </div>
          <div class="col-1">
            <img src="/img/threespace/payment/pointer-right.svg" />
          </div>
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-search.svg" />
          </div>
          <div class="col-1">
            <img src="/img/threespace/payment/pointer-right.svg" />
          </div>
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-scan.svg" />
          </div>
        </div>
        <div
          class="row d-flex justify-content-center align-items-center text-center mt-2 mb-5"
        >
          <div class="col-4">{{ $t("wallet.klip.exeKakao") }}</div>
          <div class="col-3">{{ $t("wallet.klip.search") }}</div>
          <div class="col-4">{{ $t("wallet.klip.scanCode") }}</div>
        </div>
      </template>
      <!-- 카카오톡 클립(모바일)으로 연결 -->
      <template v-if="form.isKakaoKlipMobile">
        <div class="w-100 text-center my-5">
          <p>
            <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
            <span class="text-danger">{{ time }}</span>
          </p>
        </div>
      </template>
    </b-modal>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import Web3 from "web3";
import Caver from "caver-js";
import CommonUtil from "@/mixins/CommonUtil";
import NeopinConnect from "nptconnect-client";
import {getResult, prepare, request} from "klip-sdk";
import QRCode from "qrcode";
import WalletConnectUtil from "@/mixins/WalletConnectUtil";

export default {
  name: "bridgeModal",
  mixins: [
    CommonUtil,
    WalletConnectUtil
  ],
  data: function () {
    return {
      visibleOrigin: false,
      visibleDestination: false,
      balance: 0,
      recipient: "",
      originSelected: {},
      destinationSelected: {},
      convertAmount: "",
      convertedToken: "PACE",
      receiveAmount: "",
      swapFee: 0,
      swapFeeToken : "ETHEREUM",
      agreeNotice: false,
      form: this.getInitPage(),
      klip: this.getInitKakaoKlip(),
      isTimeout: false,
      date: this.$moment(60 * 3 * 1000),
      sort: [
        { id: "displayDatetime", desc: "desc" },
        { id: "idx", desc: "desc" }
      ],
      originOptions: [
        {
          value: { id: "ethereum"},
          text: this.$t("bridge.ethereum"),
        },
        {
          value: { id: "klaytn"},
          text: this.$t("bridge.klaytn"),
        },
        {
          value: { id: "polygon"},
          text: this.$t("bridge.polygon"),
        },
      ],
      destinationOptions: [
        {
          value: { id: "ethereum"},
          text: this.$t("bridge.ethereum"),
        },
        {
          value: { id: "klaytn"},
          text: this.$t("bridge.klaytn"),
        },
      ],
    }
  },
  computed: {
    ...mapState(["wallet"]),
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    }),
    time: function() {
      return this.date.format("mm : ss");
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getBridgeInfo();
    })
  },
  methods: {
    ...mapActions("wallet", ["getBridgeInfo", "getTransactionResult", "getNonce"]),
    show() {
      this.$refs["bridgeModal"].show();
    },
    hide() {
      this.$refs["bridgeModal"].hide();
    },
    changeNetwork() {
      let temp = this.originSelected;
      this.originSelected = this.destinationSelected;
      this.destinationSelected = temp;

      this.convertAmount = 0;
      this.receiveAmount = 0;


      if(this.originSelected.value.id === 'ethereum'){
        this.destinationSelected = {value: { id: "klaytn"}, text: this.$t("bridge.klaytn")};
        this.balance = parseFloat(Math.floor(this.wallet.balance.pace.quantity * 100) / 100).toFixed(2);
        this.swapFee = this.wallet.bridgeInfo.ethSwapFee;
        this.swapFeeToken = "ETHEREUM"
        this.convertedToken = "oPACE"
      } else {
        this. destinationSelected = {value: { id: "ethereum"}, text: this.$t("bridge.ethereum")};
        this.balance = parseFloat(Math.floor(this.wallet.balance.kpace.quantity * 100) / 100).toFixed(2);
        this.swapFee = this.wallet.bridgeInfo.klaySwapFee;
        this.swapFeeToken = "KLAYTN"
        this.convertedToken = "PACE"
      }
    },
    onClickMaxBtn () {
      this.convertAmount = parseFloat(Math.floor(this.balance * 100) / 100).toFixed(2);
      this.receiveAmount = parseFloat(Math.floor(this.balance * 100) / 100).toFixed(2);
    },
    onChangeAmount() {
      this.convertAmount = this.convertAmount * 1

      this.receiveAmount = parseFloat(Math.floor(this.convertAmount * 100) / 100).toFixed(2);
      if(this.receiveAmount > Math.floor(this.balance)) {
        this.receiveAmount = this.balance;
      }
    },
    onClickConvert() {
      if(this.checkCondition()){
        this.$refs.bridgeModal.hide();
        this.$refs.convertModal.show();
      }
    },
    checkCondition() {
      if(this.balance - this.receiveAmount < 0) {
        alert("변환할 수 있는 양을 초과하였습니다.")
        return false;
      }

      if(!this.originSelected.text || !this.destinationSelected) {
        alert("네트워크가 선택되지 않았습니다.")
        return false;
      }

      if(!Web3.utils.isAddress(this.recipient)) {
        alert("올바른 주소를 입력해주세요.")
        return false;
      }

      if(!this.receiveAmount >= 1) {
        alert("변환하려는 수량이 너무 적습니다.")
        return false;
      }

      return true;
    },
    onClickConvertNow() {
      if(!this.agreeNotice){
        alert("Check Requirement")
        return;
      }

      let walletProvider;

      if(this.originSelected.value.id === "ethereum"){
        walletProvider = this.getEthereumWalletProvider;
      } else if(this.originSelected.value.id === "klaytn") {
        walletProvider = this.getKlaytnWalletProvider;
      }


      if(walletProvider === "Kaikas"){
        if(this.wallet.bridgeInfo.klayApproval) {
          this.kaikasConvert();
        }else {
          this.kaikasApproval();
        }
      } else if(walletProvider === "Klip") {
        if(this.wallet.bridgeInfo.klayApproval) {
          this.klipConvert();
        }else {
          this.klipApproval();
        }
      } else if(walletProvider === "Neopin") {
        if(this.wallet.bridgeInfo.klayApproval) {
          this.neopinConvert();
        }else {
          this.neopinApproval();
        }
      } else if (walletProvider === "MetaMask") {
        if(this.wallet.bridgeInfo.ethApproval){
          this.metamaskConvert();
        } else {
          this.metamaskApproval();
        }
      } else if (walletProvider.includes("wc-")) {
        if(this.wallet.bridgeInfo.ethApproval){
          this.walletConnectConvert();
        } else {
          this.walletConnectApproval();
        }
      } else {
        alert("We cannot find wallet provider");
      }
    },
    klipConvert(){
      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = this.wallet.bridgeInfo.klayBridgeContract;
      const value = `${Web3.utils.toWei(this.wallet.bridgeInfo.klaySwapFee, 'ether')}`;
      const abi = JSON.stringify(this.getRequestSwapABI());
      const params = `["${this.destinationSelected.value.id}", "${Web3.utils.toWei(this.receiveAmount, "ether")}", "${this.recipient}"]`;

      prepare
        .executeContract({ bappName, from, to, value, abi, params })
        .then(data => {
          this.klip.requestKey = data.request_key;
          this.sendNext(data,"convert");
        })
        .catch(error => {
          this.$log.error(error);
          this.closeApprove();
          alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
        });
    },
    klipApproval(){
      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = process.env.VUE_APP_KPACE_ADDRESS;
      const value = "0";
      const _amount = 16 ** 64;
      const amount = _amount.toLocaleString('fullwide', {useGrouping:false});
      const abi = JSON.stringify(this.getApprovalABI());
      const params = `["${this.wallet.bridgeInfo.klayBridgeContract}", "${amount}"]`;

      prepare
        .executeContract({ bappName, from, to, value, abi, params })
        .then(data => {
          this.klip.requestKey = data.request_key;
          this.sendNext(data,"approval");
        })
        .catch(error => {
          this.$log.error(error);
          this.closeApprove();
          alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
        });
    },
    neopinConvert() {
      const session = this.getCachedSession();

      if (session) {
        const connector = new NeopinConnect({ session })
        const caver = new Caver();
        const data = caver.klay.abi.encodeFunctionCall(
          this.getRequestSwapABI(),
          [
            this.destinationSelected.value.id,
            Web3.utils.toWei(this.receiveAmount, "ether"),
            this.recipient,
          ]
        )

        this.getNonce(this.me.klaytnAddr).then((nonce) => {
          const tx = {
            from: this.me.klaytnAddr,
            to: this.wallet.bridgeInfo.klayBridgeContract,
            gas: 300000,
            gasPrice: caver.utils.convertToPeb(750, 'Ston'),
            value: caver.utils.toPeb(this.wallet.bridgeInfo.klaySwapFee, 'KLAY'),
            nonce,
            data
          }

          connector.sendTransaction(tx)
            .then(async (hash) => {
              this.$log.info("Transaction hash:", hash);
              await this.sleep(2000);
              this.getTransactionResult(hash).then((receipt) => {
                if(receipt.status){
                  this.onCompleteConvert();
                }else {
                  alert("Fail")
                  this.closeConvert();
                }
              })
                .catch((error) => {
                  this.closeConvert();
                })
            })
            .catch((error) => {
              this.$log.error("Transaction error:", error);
              this.closeConvert();
            })
        })
          .catch((error) => {
            this.$log.error("Can't Get Nonce : ", error);
            this.closeConvert();
            alert(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    neopinApproval() {
      const session = this.getCachedSession();

      if (session) {
        const connector = new NeopinConnect({ session })
        const caver = new Caver();
        const amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"; // 최대 수량 64bit
        const data = caver.klay.abi.encodeFunctionCall(
          this.getApprovalABI(),
          [this.wallet.bridgeInfo.klayBridgeContract, amount]
        )

        this.getNonce(this.me.klaytnAddr).then((nonce) => {
          const tx = {
            from: this.me.klaytnAddr,
            to: process.env.VUE_APP_KPACE_ADDRESS,
            gas: 300000,
            gasPrice: caver.utils.convertToPeb(750, 'Ston'),
            value: 0,
            nonce,
            data
          }

          connector.sendTransaction(tx)
            .then(async (hash) => {
              this.$log.info("Transaction hash:", hash);
              await this.sleep(2000);
              this.getTransactionResult(hash).then((receipt) => {
                if(receipt.status){
                  this.$refs.convertModal.hide();
                }else {
                  alert("Fail")
                  this.$refs.convertModal.hide();
                }
              })
                .catch((error) => {
                  this.closeApprove(error);
                })
            })
            .catch((error) => {
              this.$log.error("Transaction error:", error);
              this.closeApprove(error);
            })
        })
          .catch((error) => {
            this.$log.error("Can't Get Nonce : ", error);
            this.closeApprove(error);
            alert(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    async walletConnectConvert(){
      const provider = await this.getWalletConnectProvider();

      if(provider){
        const web3 = new Web3(provider);

        const data = web3.eth.abi.encodeFunctionCall(
          this.getRequestSwapABI(),
          [
            this.destinationSelected.value.id,
            Web3.utils.toWei(this.receiveAmount, "ether"),
            this.recipient,
          ]
        );

        const tx = {
          from: this.me.ethereumAddr,
          to: this.wallet.bridgeInfo.ethBridgeContract,
          data
        }

        const infuraWeb3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_INFURA_URL))
        let maxPolling = 10;
        let pollingInterval = 15000;

        provider.request({
          method: 'eth_sendTransaction',
          params: [tx]
        })
          .then((hash)  => {
            this.$log.info("Transaction hash:", hash); //TODO
            this.$store.commit("setShowLoading", {
              show: true,
              msg: this.$t("loading.transaction.progressing")
            });
            const timer = setInterval(() => infuraWeb3.eth.getTransactionReceipt(hash).then((result) => {
              if(maxPolling > 0) {
                if(result) {
                  if(result.status){
                    this.$store.commit("setShowLoading", {show: false, msg: ""});
                    this.$refs.convertModal.hide();
                    clearInterval(timer);
                  }else {
                    this.$store.commit("setShowLoading", {show: false, msg: ""});
                    alert("Fail")
                    this.$refs.convertModal.hide();
                    clearInterval(timer);
                  }
                }
              } else {
                clearInterval(timer);
                this.$refs.convertModal.hide();
              }
              maxPolling--;
            }), pollingInterval);
          })
          .catch((error) => {
            alert(error)
            this.$refs.convertModal.hide();
            this.$log.error("Transaction error:", error); //TODO
            this.$store.commit("setShowLoading", {show: false, msg: ""});
          })
      }
    },
    async walletConnectApproval() {
      const provider = await this.getWalletConnectProvider();

      if(provider){
        const web3 = new Web3(provider);
        const amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"; //최대 수량 64bit
        const data = web3.eth.abi.encodeFunctionCall(
          this.getApprovalABI(),
          [this.wallet.bridgeInfo.ethBridgeContract, amount]
        );

        const tx = {
          from: this.me.ethereumAddr,
          to: process.env.VUE_APP_PACE_ADDRESS,
          data
        }

        const infuraWeb3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_INFURA_URL))
        let maxPolling = 10;
        let pollingInterval = 15000;

        provider.request({
          method: 'eth_sendTransaction',
          params: [tx]
        })
          .then((hash)  => {
            this.$log.info("Transaction hash:", hash); //TODO
            this.$store.commit("setShowLoading", {
              show: true,
              msg: this.$t("loading.transaction.progressing")
            });
            const timer = setInterval(() => infuraWeb3.eth.getTransactionReceipt(hash).then((result) => {
              if(maxPolling > 0) {
                if(result) {
                  if(result.status){
                    this.walletConnevtConvert(result);
                    this.$store.commit("setShowLoading", {show: false, msg: ""});
                    clearInterval(timer);
                  }else {
                    this.$store.commit("setShowLoading", {show: false, msg: ""});
                    this.closeApprove();
                    clearInterval(timer);
                  }
                }
              } else {
                clearInterval(timer);
              }
              maxPolling--;
            }), pollingInterval);
          })
          .catch((error) => {
            this.$log.error("Transaction error:", error); //TODO
            this.$store.commit("setShowLoading", {show: false, msg: ""});
            this.closeApprove(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    metamaskApproval() {
      if (this.checkMetamask()) {
        const web3 = new Web3(window.ethereum);
        const amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"; //최대 수량 64bit
        const data = web3.eth.abi.encodeFunctionCall(
          this.getApprovalABI(),
          [this.wallet.bridgeInfo.ethBridgeContract, amount]
        )

        web3.eth.sendTransaction({
          from: this.me.ethereumAddr,
          to: process.env.VUE_APP_PACE_ADDRESS,
          data
        })
          .on("transactionHash", (hash) => {
            this.$log.info("sendTransaction hash:", hash); //TODO
            this.$store.commit("setShowLoading", {
              show: true,
              msg: this.$t("loading.transaction.progressing")
            });
          })
          .on("receipt", (result) => {
            this.$log.info("sendTransaction result:", result);  //TODO
            if (result.status) {
              this.metamaskConvert();
              this.$store.commit("setShowLoading", {show: false, msg: ""});
            } else {
              this.$store.commit("setShowLoading", {show: false, msg: ""});
              alert("Fail")
              this.closeApprove();
            }
          })
          .on("error", (error) => {
            this.$log.info("sendTransaction error:", error); //TODO
            this.$store.commit("setShowLoading", {show: false, msg: ""});
            this.closeApprove(error);
          });
      }
    },
    metamaskConvert() {
      if (this.checkMetamask()) {
        const web3 = new Web3(window.ethereum);

        const data = web3.eth.abi.encodeFunctionCall(
          this.getRequestSwapABI(),
          [
            this.destinationSelected.value.id,
            Web3.utils.toWei(this.receiveAmount, "ether"),
            this.recipient,
          ]
        );

        web3.eth.sendTransaction({
          from: this.me.ethereumAddr,
          to: this.wallet.bridgeInfo.ethBridgeContract,
          data
        })
          .on("transactionHash", (hash) => {
            this.$log.info("sendTransaction hash:", hash); //TODO
            this.$store.commit("setShowLoading", {
              show: true,
              msg: this.$t("loading.transaction.progressing")
            });
          })
          .on("receipt", (result) => {
            this.$log.info("sendTransaction result:", result);  //TODO
            if (result.status) {
              this.$store.commit("setShowLoading", {show: false, msg: ""});
              this.$refs.convertModal.hide();
            } else {
              this.$store.commit("setShowLoading", {show: false, msg: ""});
              alert("Fail")
            }
          })
          .on("error", (error) => {
            // this.$log.info("sendTransaction error:", error); //TODO
            this.$store.commit("setShowLoading", { show: false, msg: "" });
            this.$refs.convertModal.hide();
          });
      }
    },
    closeConvert(){
      this.$refs.convertModal.hide();
    },
    onCompleteConvert(receipt){
      if (receipt.status) {
        this.closeConvert();
      } else {
        alert("Fail")
      }
      this.closeConvert();
    },
    closeApprove(){

    },
    onCompleteKlaytnApproval(receipt){
      if (receipt.status) {
        this.kaikasConvert();
      } else {
        alert("Fail")
      }
      this.closeApprove();
    },
    kaikasApproval() {
      if (this.checkKaikas()) {
        const caver = new Caver(window.klaytn);
        const amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"; // 최대 수량 64bit
        const data = caver.klay.abi.encodeFunctionCall(
          this.getApprovalABI(),
          [this.wallet.bridgeInfo.klayBridgeContract, amount]
        )

        caver.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: this.me.klaytnAddr,
          to: process.env.VUE_APP_KPACE_ADDRESS,
          gas: 300000,
          data
        })
          .on('error', this.closeApprove)
          .on('receipt', this.onCompleteKlaytnApproval);
      }
    },
    kaikasConvert() {
      if(this.checkKaikas()) {

        const caver = new Caver(window.klaytn);

        const data = caver.klay.abi.encodeFunctionCall(
          this.getRequestSwapABI(),
          [
            this.destinationSelected.value.id,
            Web3.utils.toWei(this.receiveAmount, "ether"),
            this.recipient
          ]
        );

        caver.klay
          .sendTransaction({
            type: "SMART_CONTRACT_EXECUTION",
            from: this.me.klaytnAddr,
            to: this.wallet.bridgeInfo.klayBridgeContract,
            value: caver.utils.toPeb(this.wallet.bridgeInfo.klaySwapFee, 'KLAY'),
            gas: 300000,
            data
          })
          .on("error", this.closeConvert)
          .on("receipt", this.onCompleteConvert);
      }
    },
    showSortByFilter(type) {
      if(this.me.klaytnAddr && this.me.ethereumAddr) {

      } else if(this.me.klaytnAddr) {
        this.originOptions = [
          {
            value: { id: "klaytn"},
            text: this.$t("bridge.klaytn"),
          },
          {
            value: { id: "polygon"},
            text: this.$t("bridge.polygon"),
          }]
      } else if(this.me.ethereumAddr) {
        this.originOptions = [
          {
            value: { id: "ethereum"},
            text: this.$t("bridge.ethereum"),
          },
          {
            value: { id: "polygon"},
            text: this.$t("bridge.polygon"),
          }]
      }

      if(type === "origin"){
        this.visibleOrigin = !this.visibleOrigin;
      }
      // else if(type === "destination") {
      //   this.visibleDestination = !this.visibleDestination;
      // }
    },
    onClickSortByItem(item, type) {
      this.sort[0] = item.value;
      this.convertAmount = 0;
      this.receiveAmount = 0;
      if(type === 'origin'){
        this.visibleOrigin = false;
        this.originSelected = item;
        if(item.value.id === 'ethereum'){
          this.destinationSelected = {value: { id: "klaytn"}, text: this.$t("bridge.klaytn")};
          this.balance = parseFloat(Math.floor(this.wallet.balance.pace.quantity * 100) / 100).toFixed(2);
          this.swapFee = this.wallet.bridgeInfo.ethSwapFee;
          this.swapFeeToken = "ETHEREUM"
          this.convertedToken = "oPACE"
        } else {
          this. destinationSelected = {value: { id: "ethereum"}, text: this.$t("bridge.ethereum")};
          this.balance = parseFloat(Math.floor(this.wallet.balance.kpace.quantity * 100) / 100).toFixed(2);
          this.swapFee = this.wallet.bridgeInfo.klaySwapFee;
          this.swapFeeToken = "KLAYTN"
          this.convertedToken = "PACE"
        }
      }
      // else if(type === 'destination'){
      //   this.visibleDestination = false;
      //   this.destinationSelected = item;
      // }
    },
    checkMetamask() {
      if (window.ethereum === undefined || !window.ethereum) {
        alert(this.$t("wallet.validation.checkMetamask"));
        return false;
      }

      const metamask = window.ethereum;

      if (
        metamask.selectedAddress !== undefined &&
        metamask.selectedAddress !== null &&
        metamask.selectedAddress !== ""
      ) {
        if (metamask.selectedAddress.toLowerCase() !== this.me.ethereumAddr.toLowerCase()) {
          alert(this.$t("wallet.validation.checkWalletAddr"));
          return false;
        }
      } else {
        alert(this.$t("wallet.validation.checkWalletAddr"));
        return false;
      }

      if (
        metamask.networkVersion === undefined ||
        metamask.networkVersion === null ||
        metamask.networkVersion.toString() !== process.env.VUE_APP_ETHEREUM_CHAIN_ID
      ) {
        alert(this.$t("wallet.validation.checkNetwork"));
        return false;
      }
      return true;
    },
    getRequestSwapABI() {
      return {
        "inputs": [
          {
            "internalType": "string",
            "name": "network",
            "type": "string"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "receiver",
            "type": "address"
          }
        ],
        "name": "swapRequest",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      }
    },
    getApprovalABI() {
      return {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "approve",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      }
    },
    modalEvent(isVisible) {
      if (isVisible) {
        // open event hook
      } else {
        // close event hook ***>> modal data init here
        this.stakingAmount = "";
      }
    },
    getInitKakaoKlip() {
      return {
        qrcode: null,
        requestKey: null
      };
    },
    sendNext(data, functionType) {
      if (data.err) {
        if (data.code === 6412) {
          alert("Request Failed");
        } else {
          alert("Request Failed");
        }
      } else {
        if (data.request_key) {
          // 타이머
          this.isTimeout = false;
          this.date = this.$moment(60 * 3 * 1000); // 3분
          clearInterval(this.timer);
          clearInterval(this.totalTimer);

          if (this.isMobile){
            // 카카오톡 클립 > 정보제공 요청
            request(data.request_key, true);
            this.form = this.getInitPage();
            this.form.isKakaoKlipMobile = true;
          } else {
            // QR 코드 생성
            this.createQRCode("https://klipwallet.com/?target=/a2a?request_key=" + data.request_key);
            if (this.klip.qrcode != null) {
              this.$refs["klipModal"].show();
            }
          }
          // 결과 조회 전체 타이머(QR 남은 시간)
          this.setTotalTimer();
          // 결과 조회
          this.setResultTimer(data.request_key, functionType);
        } else {
          if(this.isMobile){
            alert(this.$t("stake.failTryAgain"));
          } else {
            alert(this.$t("stake.failKakaoQR"));
          }
        }
      }
    },
    setTotalTimer() {
      if (!this.isTimeout) {
        this.totalTimer = setInterval(() => {
          this.date = this.date.clone().subtract(1, "seconds");
          if (this.time === "00 : 00") {
            clearInterval(this.timer);
            clearInterval(this.totalTimer);
            this.hide();
          }
        }, 1000);
      }
    },
    setResultTimer(reqeustKey, functionType) {
      if (!this.isTimeout) {
        this.timer = setInterval(() => {
          getResult(this.klip.requestKey)
            .then(data => {
              if (data.status === "completed") {
                if (data.result.status === "success") {
                  clearInterval(this.timer);
                  this.isTimeout = true;
                  // 열린 모달 닫기
                  this.$refs["klipModal"].hide();
                  if(functionType === 'convert'){
                    this.onCompleteConvert(data.result);
                  }else if(functionType === 'approval'){
                    this.klipConvert();
                  }
                } else if (data.result.status === "pending") {
                  // pending : success될때까지 실행
                  this.isTimeout = false;
                } else {
                  clearInterval(this.timer);
                  this.isTimeout = true;
                  this.$refs["klipModal"].hide();
                  if(functionType === 'convert'){
                    this.closeConvert();
                  }
                }
              } else if (data.status === "canceled") {
                this.$refs["klipModal"].hide();
                this.closeModal();
                if(functionType === 'convert'){
                  this.closeConvert();
                }
              }
            })
            .catch(error => {
              this.$refs["klipModal"].hide();
              this.$log.error(error);
              clearInterval(this.timer);
            });
        }, 3000);
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    closeModal() {
      clearInterval(this.timer);
      clearInterval(this.totalTimer);
    },
    getInitPage() {
      return {
        title: "CONNECT",
        isDefault: false,
        isKakaoKlipPc: false,
        isKakaoKlipMobile: false,
        errorMessage: null
      };
    },
    createQRCode(_klipURL) {
      QRCode.toDataURL(_klipURL, async (err, url) => {
        this.form = this.getInitPage();
        if (err) {
          this.klip.qrcode = null;
        } else {
          this.form.isKakaoKlipPc = true;
          this.form.title = this.$t("wallet.modal.klipQRTitle");
          this.klip.qrcode = url;
        }
      });
    },
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return parseFloat(Math.floor(value * 100) / 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
}
</script>
