<template>
  <div id="PaceDashboard">
    <div class="dashboard-wrapper">
      <div class="dashboard-title">
        <div class="dashboard-title-top">
          <div>Rewards</div>
          <div class="bridge-button disabled">$PACE BRIDGE</div>
        </div>
        <div class="dashboard-title-bottom">PACE TOKEN</div>
      </div>
      <div class="dashboard-content">
        <div class="dashboard-content-balance">
          <div class="dashboard-content-balance-item">
            <div class="dashboard-content-balance-header">
              {{ $t("claim.walletBalance") }}
            </div>
            <div class="dashboard-content-balance-wrapper">
              <div class="dashboard-content-balance-token">
                <img width="35" src="/img/threespace/company/pace_logo.png">
              </div>
              <div class="dashboard-content-balance-amount">{{wallet.balance.pace.quantity | amount}}</div>
              <span class="dashboard-content-balance-amount-symbol">{{wallet.balance.pace.symbol}}</span>
            </div>
          </div>
          <div class="dashboard-content-balance-item">
            <div class="dashboard-content-balance-header">
              {{ $t("claim.walletBalance") }}
            </div>
            <div class="dashboard-content-balance-wrapper">
              <div class="dashboard-content-balance-token">
                <img width="35" src="/img/threespace/company/opace_logo.png">
              </div>
              <div class="dashboard-content-balance-amount">{{wallet.balance.kpace.quantity | amount}}</div>
              <span class="dashboard-content-balance-amount-symbol">{{wallet.balance.kpace.symbol}}</span>
            </div>
          </div>
        </div>
        <div class="dashboard-content-header">
          <div>{{ $t("claim.balance") }}</div>
          <div>{{ $t("claim.available") }}</div>
        </div>
        <div class="dashboard-content-detail">
          <div class="dashboard-content-detail-wrapper">
            <div class="dashboard-content-detail-token">
              <img width="35" src="/img/threespace/company/pace_logo.png">
            </div>
            <div class="dashboard-content-detail-amount">{{wallet.claim.pace | amount}}</div>
            <span class="dashboard-content-detail-amount-symbol">{{wallet.balance.pace.symbol}}</span>
            <b-input
              class="dashboard-content-detail-input pc"
              placeholder="Minimum Claim Amount 100"
              v-model="claim.amount.PACE"
            />
            <b-input
              class="dashboard-content-detail-input mobile"
              placeholder="Min. 100"
              v-model="claim.amount.PACE"
            />
            <span class="dashboard-content-detail-input-symbol">{{wallet.balance.pace.symbol}}</span>
          </div>
          <CButton
            class="dashboard-content-detail-btn"
            @click="onClickClaim('PACE', me.ethereumAddr)"
          >
            {{ $t("claim.claim") }}
          </CButton>
        </div>
        <div class="dashboard-content-detail">
          <div class="dashboard-content-detail-wrapper">
            <div class="dashboard-content-detail-token">
              <img width="35" src="/img/threespace/company/opace_logo.png">
            </div>
            <div class="dashboard-content-detail-amount">{{wallet.claim.opace | amount}}</div>
            <span class="dashboard-content-detail-amount-symbol">{{wallet.balance.kpace.symbol}}</span>
            <b-input
              class="dashboard-content-detail-input pc"
              placeholder="Minimum Claim Amount 100"
              v-model="claim.amount.oPACE"
            />
            <b-input
              class="dashboard-content-detail-input mobile"
              placeholder="Min. 100"
              v-model="claim.amount.oPACE"
            />
            <span class="dashboard-content-detail-input-symbol">{{wallet.balance.kpace.symbol}}</span>
          </div>
          <CButton
            class="dashboard-content-detail-btn kpace"
            @click="onClickClaim('oPACE', me.klaytnAddr)"
          >
            {{ $t("claim.claim") }}
          </CButton>
        </div>
        <div class="dashboard-content-detail">
          <div class="dashboard-content-detail-wrapper coming">
            <div class="dashboard-content-detail-coming-soon">
              <CImg
                src="/img/threespace/claim/coming_soon_text.png"
              />
              <CImg
                src="img/threespace/claim/pace_by_polygon.png"
              />
            </div>
          </div>
          <CButton
            class="dashboard-content-detail-btn coming"
            @click="onClickClaim('pPACE', me.polygonAddr)"
          >
            {{ $t("claim.claim") }}
          </CButton>
        </div>
      </div>

      <div class="dashboard-transaction">
        <div class="dashboard-transaction-title">
          Transaction
        </div>
        <div id="transactionTable" class="dashboard-transaction-table">
          <div class="dashboard-transaction-table-row-header">
            <div class="action tr">{{ $t("claim.action") }}</div>
            <div class="id tr">{{ $t("claim.tokenID") }}</div>
            <div class="quantity tr">{{ $t("claim.quantity")}}</div>
            <div class="hash tr">{{ $t("claim.hash") }}</div>
            <div class="status tr">{{ $t("claim.status") }}</div>
            <div class="date tr">{{ $t("claim.date") }}</div>
          </div>
          <template v-if="wallet.transactionListContent.length > 0">
            <template v-for="(item, index) in wallet.transactionListContent">
              <div class="dashboard-transaction-table-row" :key="'item' + index">
                <div class="index tr">{{ index + 1 + (currentPage - 1) * perPage }}</div>
                <div class="action tr">{{item.transactionType}}</div>
                <div class="id tr">{{item.tokenId}}</div>
                <template v-if="item.createdAt < Date('2021-12-26T00:00:00') && item.amount === 0">
                  <div class="quantity tr">{{ $t("claim.noti1") }}</div>
                  <div class="currency tr">{{ $t("claim.noti2") }}</div>
                </template>
                <template v-else>
                  <div class="quantity tr">{{item.amount | amount}}</div>
                  <div class="currency tr">{{getCurrency(item)}}</div>
                </template>
                <div class="hash tr" @click="openScope(item)">{{item.transactionHash | hash }} </div>
                <div class="status tr">{{item.transactionStatus}}</div>
                <div class="date tr">{{item.createdAt | moment("YYYY.MM.DD")}}</div>
              </div>
            </template>
          </template>
          <div class="dashboard-transaction-table-row" v-else>
            <div class="empty tr">No transactions</div>
          </div>
        </div>
        <b-pagination
          v-if="wallet.transactionList.totalElements > 5"
          aria-controls="transactionTable"
          align="center"
          hide-ellipsis
          v-model="currentPage"
          :total-rows="wallet.transactionList.totalElements"
          :per-page="perPage"
          :current-page="currentPage"
          v-on:input="onPaging"
        />
      </div>

    </div>
    <b-modal
      id="claimModal"
      ref="claimModal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      body-class="text-center text-black my-4"
    >
      <div class="claim-modal-title">{{ $t("claim.claimRequest") }}</div>
      <div class="claim-modal-content">
        <b-img :src="claim.symbolImg" width="50"></b-img>
        <div class="claim-modal-content-right">
          <span class="value">{{claim.amount[claim.symbol] | amount}}</span>
          <span class="text">{{ claim.symbol }}</span>
        </div>
      </div>
      <div class="claim-modal-address">
        {{ $t("claim.walletAddr") }}
        <div class="claim-modal-address-container">{{ claim.addr }}</div>
      </div>
      <!-- Ethereum -->
      <template v-if="claim.symbol === 'PACE'">
        <div class="claim-modal-notification">
          <div class="claim-modal-notification-title">{{ $t("claim.guide.title") }}</div>
          <p>• {{ replaceMultiLang($t("claim.guide.content1"), '{network}', 'Ethereum') }}</p>
          <p>• {{ replaceMultiLang(replaceMultiLang($t("claim.guide.content2"), '{token}', 'PACE'), '{network}', 'Ethereum') }}</p>
          <p>• {{ $t("claim.guide.content3") }}</p>
        </div>
        <div class="w-100 text-center mt-5">
          <div class="text-success mb-3">
            <template v-if="requestClaimPace">
              <b-spinner small type="grow" class="mr-2"></b-spinner>
              {{ $t("claim.processing") }}
            </template>
          </div>
          <b-button
            squared
            variant="secondary"
            class="px-4 py-2 mr-2"
            @click="closeClaimModal()"
          >
            {{ $t("button.close") }}
          </b-button>
          <b-button
            squared
            variant="primary"
            class="text-white px-4 py-2 ml-2"
            @click="claimRequest()"
            :disabled="requestClaimPace"
          >
            {{ $t("button.confirm") }}
          </b-button>
        </div>
      </template>
      <!-- Klaytn -->
      <template v-else-if="claim.symbol === 'oPACE'">
        <div class="claim-modal-notification">
          <div class="claim-modal-notification-title">{{ $t("claim.guide.title") }}</div>
          <p>• {{ replaceMultiLang($t("claim.guide.content1"), '{network}', 'Klaytn') }}</p>
          <p>• {{ replaceMultiLang(replaceMultiLang($t("claim.guide.content2"), '{token}', 'oPACE'), '{network}', 'Klaytn') }}</p>
          <p>• {{ $t("claim.guide.content3") }}</p>
        </div>
        <div class="w-100 text-center mt-5">
          <div class="text-success mb-3">
            <template v-if="requestClaimKpace">
              <b-spinner small type="grow" class="mr-2"></b-spinner>
              {{ $t("claim.processing") }}
            </template>
          </div>
          <b-button
            squared
            variant="secondary"
            class="px-4 py-2 mr-2"
            @click="closeClaimModal()"
          >
            {{ $t("button.close") }}
          </b-button>
          <b-button
            squared
            variant="primary"
            class="text-white px-4 py-2 ml-2"
            @click="claimRequest()"
            :disabled="requestClaimKpace"
          >
            {{ $t("button.confirm") }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="klipClaimModal"
      ref="klipClaimModal"
      hide-header
      hide-footer
      centered
      body-class="text-center text-black my-4"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="$refs.klipClaimModal.hide()">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <!-- 카카오톡 클립(PC)으로 연결 -->
      <template v-if="form.isKakaoKlipPc">
        <div class="w-100 d-flex justify-content-center align-items-center">
          <img
            src="/img/threespace/company/kakao_klip_logo_color.png"
            width="50"
            class="float-left"
          />
          <h5 class="ml-2 mb-0">{{ $t("wallet.modal.klipQRTitle") }}</h5>
        </div>
        <div class="w-100 d-flex justify-content-center">
          <img :src="klip.qrcode" width="160" height="160" class="border-1" />
        </div>
        <div class="w-100 text-center">
          <p>
            <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
            <span class="text-danger">{{ time }}</span>
          </p>
        </div>
        <div class="w-100 text-center mt-3">
          <p>
            {{ $t("wallet.modal.klipQRdescription") }}
          </p>
          <p class="text-gray">
            {{ $t("wallet.modal.klipQRdescription2") }}
          </p>
        </div>
        <div class="row d-flex justify-content-center align-items-center mt-3">
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-logo.svg" />
          </div>
          <div class="col-1">
            <img src="/img/threespace/payment/pointer-right.svg" />
          </div>
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-search.svg" />
          </div>
          <div class="col-1">
            <img src="/img/threespace/payment/pointer-right.svg" />
          </div>
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-scan.svg" />
          </div>
        </div>
        <div
          class="row d-flex justify-content-center align-items-center text-center mt-2 mb-5"
        >
          <div class="col-4">{{ $t("wallet.klip.exeKakao") }}</div>
          <div class="col-3">{{ $t("wallet.klip.search") }}</div>
          <div class="col-4">{{ $t("wallet.klip.scanCode") }}</div>
        </div>
      </template>
      <!-- 카카오톡 클립(모바일)으로 연결 -->
      <template v-if="form.isKakaoKlipMobile">
        <div class="w-100 text-center my-5">
          <p>
            <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
            <span class="text-danger">{{ time }}</span>
          </p>
        </div>
      </template>
    </b-modal>

    <!-- simple ImagePopup -->
    <EventPopup
      ref="simpleImagePopupByEvent"
      @onClickPopupNoneSeenWeekBtn="onClickPopupNoneSeenWeekBtn"
    />

    <BridgeModal
      ref="bridgeModal"
      @onclick="onClickBridge"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Caver from "caver-js";
import { prepare, request, getResult } from "klip-sdk";
import QRCode from "qrcode";
import EventPopup from "@/views/threespace/event/SimpleImagePopupBySystemMaintenance.vue";
import NeopinConnect from "nptconnect-client";
import BridgeModal from "./bridge/bridgeModal";

export default {
  name: "Claim",
  components: {
    BridgeModal,
    EventPopup
  },
  metaInfo() {
    return {
      title: this.$t("meta.claim.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.claim.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.claim.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.claim.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.claim.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ]
    }
  },
  data: function () {
    return {
      isBusy: false,
      isLast: false,
      perPage: 5,
      currentPage: 1,
      sort: [{ id: "idx", desc: "desc" }],
      externalParams: {},
      claim: {
        addr: null,
        amount: {
          oPACE: null,
          PACE: null
        },
        demandAmount: 0,
        symbol: null,
        symbolImg: null
      },
      form: this.getInitPage(),
      klip: this.getInitKakaoKlip(),
      date: this.$moment(60 * 3 * 1000),
      time: 180,
      isTimeout: false,
      isEthTimeout: false,
      requestClaimPace: false,
      requestClaimKpace: false,
    }
  },
  computed: {
    ...mapState(["wallet"]),
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    }),
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
  },
  mounted() {
    this.$nextTick(() => {
      // this.openSystemMaintenancePopup();//TODO
      this.requestTransactions();
      this.getRewardTotal();
      this.getKpaceReward(this.me.klaytnAddr);
      this.getPaceBalance();
      this.getKlaytnTokenBalance(process.env.VUE_APP_KPACE_ADDRESS, this.me.klaytnAddr);
    });
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.totalTimer);
  },
  methods: {
    ...mapActions("wallet", ["getTransactions", "getKpaceReward", "getKlaytnTokenBalance", "completeClaim", "getRewardTotal", "claimPace", "getPaceBalance", "getEthereumTransactionResult", "getNonce", "getTransactionResult",]),
    ...mapActions("util", ["sendSlack"]),
    requestTransactions() {
      this.isBusy = true;
      this.getTransactions(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: []
          },
          this.externalParams
        )
      )
        .then(data => {
          this.isBusy = false;
          this.isLast = data.last;
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    openScope(item){
      const currency = item.currency
      const transaction = item.transactionHash
      let url = null;
      if(currency === "oPACE"){
        url = process.env.VUE_APP_KLAYTNSCOPE_URL + "/tx/" + transaction;
      } else if(currency === "PACE"){
        url = process.env.VUE_APP_ETHERSCAN_URL + "/tx/" + transaction;
      }

      if(url !== null){
        window.open(url, "_blank");
      }
    },
    onPaging() {
      this.requestTransactions();
    },
    onClickBridge(){
      this.$refs["bridgeModal"].show();
    },
    onClickClaim(symbol, addr) {
      // if (symbol === "PACE") {
      //   alert("시스템 점검 관계로 현재 클래임 이용이 불가하오니, 양해부탁드립니다. \n감사합니다.");
      //   return;
      // }

      if(symbol === "pPACE"){
        alert("Polygon PACE는 추후 업데이트 될 예정입니다.")
        return;
      }
      let lowerSymbol = symbol.toLowerCase();

      this.claim.symbol = symbol;
      this.claim.symbolImg = `/img/threespace/company/${lowerSymbol}_logo.png`;
      this.claim.addr = addr;
      this.claim.demandAmount = this.claim.amount[symbol];

      if (
        this.claim.demandAmount >= 100 &&
        parseFloat(this.claim.amount[symbol]) <=
          parseFloat(this.wallet.claim[lowerSymbol])
      ) {
        this.$refs.claimModal.show();
      } else {
        let alertMessage = "claim 할수 있는 수량이 부족합니다.";

        if (this.claim.demandAmount < 100) {
          alertMessage = this.$t("wallet.validation.checkProvider");
        } else if (this.claim.demandAmount > this.wallet.claim[lowerSymbol]) {
          alertMessage = "받을 수 있는 수량을 초과합니다.";
        }

        alert(alertMessage);
        return;
      }
    },
    claimRequest() {
      if (this.claim.symbol === "oPACE") {
        this.requestClaimKpace = true;
        const walletInfo = JSON.parse(localStorage.getItem("walletInfo"));
        const walletProvider =
          this.me.klaytnProvider || walletInfo.walletProvider;

        if (walletProvider === "Kaikas") {
          this.kaikasClaimRequest();
        } else if (walletProvider === "Klip") {
          this.klipClaimRequest();
        } else if (walletProvider === "Neopin") {
          this.neopinClaimRequest();
        }
      } else if (this.claim.symbol === "PACE") {
        this.requestClaimPace = true;

        const payload = {
          amount: this.claim.demandAmount,
        }
        this.claimPace(payload)
          .then((data) => {
            if(data.transactionHash && data.transactionHash !== "") {
              this.requestTransactions();
              this.getRewardTotal();
              this.setEtherResultTimer(data.transactionHash);
            } else {
              alert("클레임 요청에 실패하였습니다. 잠시후 다시 시도해 주세요.")
              this.initClaim();
              this.$refs.claimModal.hide();
            }
          })
          .catch((error) => {
            this.$log.error(error);
            if (error.data.error === "BAD_REQUESTED") {
              if (error.data.resource === "PACE_CLAIM") {
                alert("클레임하는데 오류가 발생하였습니다. 잠시후 다시 시도해 주세요.");
              }
            } else {
              alert("클레임하는데 오류가 발생하였습니다. 잠시후 다시 시도해 주세요.");
            }
            this.initClaim();
            this.$refs.claimModal.hide();
        });
      }
    },
    kaikasClaimRequest() {
      if (window.klaytn === undefined || !window.klaytn) {
        alert("We can't find kaikas wallet");
        this.initClaim();
        this.$refs.claimModal.hide()
        return;
      }

      if (
        window.klaytn.selectedAddress === null ||
        window.klaytn.selectedAddress === undefined
      ) {
        if (window.klaytn.selectedAddress.toLowerCase() !== this.me.klaytnAddr.toLowerCase()) {
          alert("Check your connected wallet address again");
          this.initClaim();
          this.$refs.claimModal.hide()
          return;
        }
      }

      if (
        window.klaytn.networkVersion.toString() !==
          process.env.VUE_APP_KLAYTN_CHAIN_ID ||
        window.klaytn.networkVersion === null ||
        window.klaytn.networkVersion === undefined
      ) {
        alert("Check your connected network again");
        this.initClaim();
        this.$refs.claimModal.hide()
        return;
      }

      const caver = new Caver(window.klaytn);
      const amount = "0x" + (this.claim.amount.oPACE * 10 ** 18).toString(16);

      const data = caver.klay.abi.encodeFunctionCall(
        {
          "name": "claim",
          "type": "function",
          "inputs": [
            {
              "internalType": "address",
              "name": "claimer",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
        },[this.me.klaytnAddr, amount])

      const result = caver.klay
        .sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: this.me.klaytnAddr,
          to: process.env.VUE_APP_CLAIM_CONTRACT_ADDRESS,
          gas: 100000,
          data
        })
        .on('error', () => {
          this.initClaim();
          this.$refs.claimModal.hide();
        })
        .on('receipt', this.onComplete)
    },
    neopinClaimRequest() {
      const session = this.getCachedSession();

      if (session) {
        const connector = new NeopinConnect({ session })
        const caver = new Caver();
        const amount = "0x" + (this.claim.amount.oPACE * 10 ** 18).toString(16);

        const data = caver.klay.abi.encodeFunctionCall(
          {
            "name": "claim",
            "type": "function",
            "inputs": [
              {
                "internalType": "address",
                "name": "claimer",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
              }
            ],
          },[this.me.klaytnAddr, amount])

        this.getNonce(this.me.klaytnAddr).then((nonce) => {
          const tx = {
            from: this.me.klaytnAddr,
            to: process.env.VUE_APP_CLAIM_CONTRACT_ADDRESS,
            gas: 100000,
            gasPrice: caver.utils.convertToPeb(750, 'Ston'),
            value: 0,
            nonce,
            data
          }

          connector.sendTransaction(tx)
            .then(async (hash) => {
              this.$log.log("sendTransaction hash:", hash);
              await this.sleep(2000);
              this.getTransactionResult(hash).then((receipt) => {
                this.onComplete(receipt);
              })
                .catch((error) => {
                  this.initClaim();
                  this.$refs.claimModal.hide();
                })
            })
            .catch((error) => {
              this.$log.error("sendTransaction error:", error);
              this.initClaim();
              this.$refs.claimModal.hide();
            })
        })
          .catch((error) => {
            this.$log.error("Can't Get Nonce : ", error);
            this.initClaim();
            this.$refs.claimModal.hide();
            alert(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    klipClaimRequest() {
      // TODO klipClaimRequest

      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = process.env.VUE_APP_CLAIM_CONTRACT_ADDRESS;
      const value = "0";
      const _amount = this.claim.amount.oPACE * 10 ** 18;
      const amount = _amount.toLocaleString('fullwide', {useGrouping:false});

      const abi = JSON.stringify({
        "name": "claim",
        "type": "function",
        "inputs": [
          {
            "internalType": "address",
            "name": "claimer",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
      });

      const params = `["${this.me.klaytnAddr}", "${amount}"]`;

      if (this.isMobile) {
          // 모바일
        prepare
          .executeContract({ bappName, from, to, value, abi, params })
          .then(data => {
            this.klip.requestKey = data.request_key;
            this.sendNextMobile(data);
          })
          .catch(error => {
            this.$log.error(error);
            this.initClaim();
            this.$refs.klipClaimModal.hide();
            alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
          });
      } else {
          // PC
        prepare
          .executeContract({ bappName, from, to, value, abi, params })
          .then(data => {
            this.klip.requestKey = data.request_key;
            this.sendNextPC(data);
          })
          .catch(error => {
            this.$log.error(error);
            this.initClaim();
            this.$refs.klipClaimModal.hide();
            alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
          });
      }
    },
    initClaim() {
      let claim = {
        addr: null,
        amount: {
          oPACE: null,
          PACE: null
        },
        demandAmount: 0,
        symbol: null,
        symbolImg: null,
      };

      this.claim = claim;
      this.requestClaimKpace = false;
      this.requestClaimPace = false;
    },
    onComplete(receipt) {
      if (receipt.status) {
        receipt.status = "Success";
      } else {
        receipt.status = "Fail";
      }

      this.$refs.claimModal.hide();

      const payload = {
        receipt,
        amount: this.claim.demandAmount,
        currency: this.claim.symbol
      }

      this.completeClaim(payload)
      .then((data) => {
        this.requestTransactions();
        this.getKpaceReward(this.me.klaytnAddr);
        this.getKlaytnTokenBalance(process.env.VUE_APP_KPACE_ADDRESS, this.me.klaytnAddr);

        this.initClaim();
        this.$refs.claimModal.hide();
      });
    },
    sendNextPC(data) {
      //this.$log.log("showKlipPayment() data=", data);
      if (data.err) {
        this.initClaim();
        this.closeClaimModal();
        if (data.code === 6412) {
          alert("요청에 실패하였습니다. 클레이 수량을 확인해주세요.");
        } else {
          alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.\n[" + data.code + "] " + data.err);
        }
      } else {
        if (data.request_key) {
          // 타이머
          this.isTimeout = false;
          this.date = this.$moment(60 * 3 * 1000); // 3분
          clearInterval(this.timer);
          clearInterval(this.totalTimer);

          // QR 코드 생성
          this.createQRCode("https://klipwallet.com/?target=/a2a?request_key=" + data.request_key);
          if (this.klip.qrcode != null) {
            this.$refs["klipClaimModal"].show();
          }

          // 결과 조회 전체 타이머(QR 남은 시간)
          this.setTotalTimer();
          // 결과 조회
          this.setResultTimer(data.request_key);
        } else {
          this.initClaim();
          this.closeClaimModal();
          alert("카카오 QR코드 생성에 실패하였습니다. 잠시후 다시 시도해 주세요.");
        }
      }
    },
    sendNextMobile(data) {
      if (data.err) {
        this.initClaim();
        this.closeClaimModal();
        if (data.code === 6412) {
          alert("요청에 실패하였습니다. 클레이 수량을 확인해주세요.");
        } else {
          alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.\n[" + data.code + "] " + data.err);
        }
      } else {
        if (data.request_key) {
          // 타이머
          this.isTimeout = false;
          this.date = this.$moment(60 * 3 * 1000); // 3분
          clearInterval(this.timer);
          clearInterval(this.totalTimer);

          // 카카오톡 클립 > 정보제공 요청
          request(data.request_key, true);
          this.form = this.getInitPage();
          this.form.isKakaoKlipMobile = true;

          // 결과 조회 전체 타이머
          this.setTotalTimer();
          // 결과 조회
          this.setResultTimer(data.request_key);
        } else {
          this.initClaim();
          this.closeClaimModal();
          alert("요청에 실패하였습니다. 잠시후 다시 시도해 주세요.");
        }
      }
    },
    setTotalTimer() {
      this.totalTimer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.totalTimer);
          this.isTimeout = true;
        }
      }, 1000);
    },
    createQRCode(_klipURL) {
      QRCode.toDataURL(_klipURL, async (err, url) => {
        this.form = this.getInitPage();
        if (err) {
          this.klip.qrcode = null;
        } else {
          this.form.isKakaoKlipPc = true;
          this.form.title = this.$t("wallet.modal.klipQRTitle");
          this.klip.qrcode = url;
        }
      });
    },
    setResultTimer() {
      if (!this.isTimeout) {
        this.timer = setInterval(() => {
          getResult(this.klip.requestKey)
            .then(data => {
              if (data.status === "completed") {

                if (data.result.status === "success") {
                  clearInterval(this.timer);

                  const receipt = {
                    transactionHash: data.result.tx_hash,
                    status: "Success"
                  }

                  const payload = {
                    receipt,
                    amount: this.claim.demandAmount,
                    currency: this.claim.symbol
                  }

                  this.completeClaim(payload)
                    .then((data) => {
                      this.requestTransactions();
                      this.getKpaceReward(this.me.klaytnAddr);
                      this.getKlaytnTokenBalance(process.env.VUE_APP_KPACE_ADDRESS, this.me.klaytnAddr);
                      this.initClaim();

                      this.$refs.claimModal.hide();
                      this.$refs.klipClaimModal.hide();
                      this.isTimeout = true;
                    })
                } else if (data.result.status === "pending") {
                  // pending : success될때까지 실행
                  this.isTimeout = false;
                } else {
                  const slackInfo = {
                    type: "PAYMENT", // PAYMENT, ORDER, ARTWORK
                    header: "oPACE리워드-전송지연",
                    productName: "oPACE CLAIM",
                    artistName: this.me.klaytnAddr,
                    job: "> Klip API result.status: fail",
                  };
                  this.sendSlack(slackInfo);
                  alert(this.$t("payment.payFailMsg"));
                }
              } else if (data.status === "canceled") {
                this.closeModal();
                this.closeClaimModal();
              }
            })
            .catch(error => {
              this.$log.error(error);
              this.initClaim();
              this.$refs.claimModal.hide();
              this.$refs.klipClaimModal.hide();
              clearInterval(this.timer);
            });
        }, 3000);
      }
    },
    setEtherResultTimer(txHash) {
      if (!this.isEthTimeout) {
        this.timer = setInterval(() => {
          this.getEthereumTransactionResult(txHash)
          .then(data => {
            if (data.transactionStatus === "Success"){
              clearInterval(this.timer);
              this.requestTransactions();
              this.getPaceBalance();
              this.getRewardTotal();
              this.initClaim();
              this.$refs.claimModal.hide();
              this.isEthTimeout = true;
            }
          })
          .catch(error => {
            this.$log.error(error);
            clearInterval(this.timer);
          });
        }, 3000);
      }
    },
    getInitPage() {
      return {
        title: "CONNECT",//this.$t("wallet.modal.description"),
        isDefault: false,
        isKakaoKlipPc: false,
        isKakaoKlipMobile: false,
        errorMessage: null,
        isLogin: false,
        isChange: false,
        isEthereum: false,
        isKlaytn: false,
      };
    },
    getInitKakaoKlip() {
      return {
        qrcode: null,
        requestKey: null
      };
    },
    closeModal() {
      this.isTimeout = true;
      this.$refs.klipClaimModal.hide();

      this.initClaim();
      this.$refs.claimModal.hide();

      clearInterval(this.timer);
      clearInterval(this.totalTimer);
    },
    closeClaimModal() {
      this.$refs.claimModal.hide();
    },
    openSystemMaintenancePopup() {
      if (this.$cookies.get("popupNotSeenForWeek3spaceMaintenance") === null) {
        this.$refs["simpleImagePopupByEvent"].show();
      }
    },
    onClickPopupNoneSeenWeekBtn() {
      this.$cookies.set("popupNotSeenForWeek3spaceMaintenance", "done", "7d");
      this.$refs["simpleImagePopupByEvent"].hide();
    },
    replaceMultiLang(str, oldStr, newStr) {
      if (str !== undefined && str !== null && str !== ""
          && oldStr !== undefined && oldStr !== null && oldStr !== ""
          && newStr !== undefined && newStr !== null && newStr !== "") {
        return str.replaceAll(oldStr, newStr);
      } else {
        return str;
      }
    },
    getCachedSession() {
      const local = localStorage ? localStorage.getItem("walletconnect") : null;

      let session = null;
      if (local) {
        session = JSON.parse(local);
      }
      return session;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    getCurrency(item) {
      let currency = item.currency;

      if(item.network === "POLYGON") {
        currency = "PACE(POLYGON)";
      }

      return currency;
    }
  },
  filters: {
    hash: value => {
      if (value.length <= 36) return value;
      return value.substring(0,36) + "..."
    },
    amount: value => {
      return parseFloat(Math.floor(value * 100) / 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
}
</script>
